import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { CONVERT_SINGLE_CURRENCY } from "../apollo/graphql/Query/convertSingleCurrency"
import styles from "../assets/styles/Pages/CurrencyPage.module.css"
import Currency from "../assets/UI/Currency"
import formatBalance from "../utils/formatBalance"
import ActionButton from "../assets/UI/ActionButton"

const CurrencyPage = () => {
  const { isoCode } = useParams()
  const walletId = JSON.parse(localStorage.getItem("wallet"))?.id

  const { loading, error, data } = useQuery(CONVERT_SINGLE_CURRENCY, {
    variables: { walletId, currency: isoCode },
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const { originalAmount, convertedAmount, originalCurrency, targetCurrency } =
    data.convertSingleCurrency

  return (
    <div className={styles.currencyPage}>
      <Currency currency={originalCurrency} variant="simple" />
      <p>
        {originalCurrency.currencyType === "FIAT" ? (
          <>
            {originalCurrency.symbol}
            {formatBalance(originalAmount)}
          </>
        ) : (
          <>
            {formatBalance(originalAmount)}
            {originalCurrency.symbol && ` ${originalCurrency.symbol}`}
          </>
        )}
      </p>
      <p>
        {targetCurrency.currencyType === "FIAT" ? (
          <>
            {targetCurrency.symbol}
            {formatBalance(convertedAmount)}
          </>
        ) : (
          <>
            {formatBalance(convertedAmount)}
            {targetCurrency.symbol && ` ${targetCurrency.symbol}`}
          </>
        )}
      </p>
      <div className={styles.actionButton}>
        <ActionButton action={"Send"} />
        <ActionButton action={"Receive"} />
        <ActionButton action={"Swap"} />
      </div>
    </div>
  )
}

export default CurrencyPage
