import { useEffect } from "react"
import { ReactTyped } from "react-typed"
import Header from "../assets/UI/Header"
import styles from "../assets/styles/Pages/VortexWeb3Page.module.css"

const VortexWeb3Page = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header logoType={"Web3"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <ReactTyped
            strings={["Soon"]}
            typeSpeed={50}
            showCursor={true}
            className={styles.typedText}
          />
        </div>
      </div>
    </>
  )
}

export default VortexWeb3Page
