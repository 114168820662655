function formatBalance(value) {
  const numberValue = Number(value)

  if (isNaN(numberValue)) {
    throw new Error("Value must be a valid number")
  }

  return numberValue.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export default formatBalance
