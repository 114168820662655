import { gql } from "@apollo/client"

export const VERIFY_PHONE_OTP = gql`
  mutation VerifyPhoneOTP($phoneNumber: String!, $otp: String!) {
    verifyPhoneOtp(phoneNumber: $phoneNumber, otp: $otp) {
      success
      errors
    }
  }
`
