import Header from "../assets/UI/Header"
import { ReactTyped } from "react-typed"
import styles from "../assets/styles/Pages/VortexPaymentPage.module.css"
import LabelButton from "../assets/UI/LabelButton"
import Input from "../assets/UI/Input"
import Logo from "../assets/UI/Logo"
import { useEffect, useState } from "react"
import Timer from "../assets/UI/Timer"
import Currency from "../assets/UI/Currency"
import { QRCodeCanvas } from "qrcode.react"
import Lottie from "react-lottie"
import animationData from "../assets/Lottie/BlockchainServer.json"
import Button from "../assets/UI/Button"
import { useNavigate } from "react-router-dom"

const VortexPaymentPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [paymentValue, setPaymentValue] = useState("")
  const [expireTime, setExpireTime] = useState("60")
  const [amount, setAmount] = useState("")

  const handlePaymentRightButtonClick = () => {
    setPaymentValue("150")
  }

  const calculateExpireTime = () => {
    const now = new Date()
    now.setMinutes(now.getMinutes() + parseInt(expireTime, 10))
    return now
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <>
      <Header logoType={"Payment"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <ReactTyped
            strings={[
              "d555af1082beb7c2ce <br> 603bfc9b227b792d48 <br> f0f39ec0890d526fc9 <br> 9a60a79589",
              "Crypto Payment <br> Worldwide",
            ]}
            typeSpeed={50}
            backSpeed={30}
            showCursor={true}
            className={styles.typedText}
          />
        </div>
        <div className={styles.secondContainer}>
          <div className={styles.secondText}>
            <p className={styles.firstSecondText}>Invoice</p>
            <p className={styles.secondSecondText}>For everyday payments</p>
          </div>
          <div className={styles.secondContent}>
            <div>
              <Currency
                currency={{
                  logoUrl:
                    "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
                  name: "Toncoin",
                  isoCode: "TON",
                }}
                variant="simple"
              />
              <div className={styles.qrCodeContainer}>
                <div className={styles.qrCode}>
                  <QRCodeCanvas
                    value={"UQvhie5vzrk9eafrbedkhohh80b1ehhou1gfvk7o0ux7jw8r"}
                    size={210}
                  />
                </div>
                <p className={styles.qrAmount}>10 TON</p>
                <div className={styles.address}>
                  <p>UQvhie5vzrk9eafrbedkhohh80b1ehhou1gfvk7o0ux7jw8r</p>
                </div>
              </div>
              <div className={styles.timer}>
                <Timer expireTime={calculateExpireTime()} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.thirdContainer}>
          <div className={styles.thirdText}>
            <p className={styles.firstThirdText}>Static invoice</p>
            <p className={styles.secondThirdText}>For fundraising</p>
          </div>
          <div className={styles.thirdContent}>
            <div style={{ height: "100%" }}>
              <Currency
                currency={{
                  logoUrl:
                    "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Tether+(USDT).png",
                  name: "Tether",
                  isoCode: "USDT",
                }}
                variant="simple"
              />
              <div className={styles.qrCodeContainer}>
                <p className={styles.qrName}>1st Static Invoice</p>
                <div className={styles.qrCode}>
                  <QRCodeCanvas
                    value={"0x93838aE68d9De80b6844Ba61d71Bb6BFB4cC9B9c"}
                    size={210}
                  />
                </div>
                <div className={styles.address}>
                  <p>0x93838aE68d9De80b6844Ba61d71Bb6BFB4cC9B9c</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.fourthContainer}>
          <div className={styles.fourthText}>
            <div className={styles.fourthTextLogo}>
              <Logo type={"AML"} />
            </div>
            <p className={styles.firstFourthText}>Blockchain transaction</p>
            <p className={styles.secondFourthText}>
              When paying via blockchain, every transaction is verified by our
              AML AI
            </p>
            <Button
              onClick={() => {
                navigate("/aml")
              }}
            >
              Learn more
            </Button>
          </div>
          <div className={styles.fourthContent}>
            <Lottie options={defaultOptions} height={500} width={400} />
          </div>
        </div>
        <div className={styles.fifthContainer}>
          <div className={styles.fifthText}>
            <div className={styles.fifthTextLogo}>
              <Logo type={"Personal"} />
            </div>
            <p className={styles.firstFifthText}>Worldwide payments</p>
            <p className={styles.secondFifthText}>
              Pay Vortex Payment invoices from your Vortex Personal with 0% fee
            </p>
            <Button
              onClick={() => {
                navigate("/")
              }}
            >
              Learn more
            </Button>
          </div>
          <div className={styles.fifthContent}>
            <LabelButton
              icon={
                "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png"
              }
            >
              Solana
            </LabelButton>
            <Input label={"Address"} placeholder={"Address"}></Input>
            <LabelButton label={"Network"} arrow={true}>
              Solana
            </LabelButton>
            <Input
              label={"Amount"}
              type={"Number"}
              placeholder={"0"}
              secondaryLabel={"150 SOL"}
              rightButtonLabel={"MAX"}
              rightText={"SOL"}
              value={paymentValue}
              onChange={(e) => setPaymentValue(e.target.value)}
              onRightButtonClick={handlePaymentRightButtonClick}
            />
          </div>
        </div>
        <div className={styles.sixthContainer}>
          <div className={styles.sixthText}>
            <p className={styles.firstSixthText}>Vortex device</p>
            <p className={styles.secondSixthText}>
              Our app for integrating cryptocurrency payments into your offline
              business
            </p>
            <Button disabled={true}>Download</Button>
          </div>
          <div className={styles.sixthContent}>
            <p className={styles.sixthContentText}>Business</p>
            <div className={styles.sixthContentButtons}>
              <LabelButton
                icon={
                  "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Ethereum+(ETH).png"
                }
                arrow={true}
              >
                {"Ethereum"}
              </LabelButton>
              <Input
                label={"Amount"}
                type={"number"}
                placeholder={"Enter amount"}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <Input
                label={"Expire Time"}
                type={"number"}
                placeholder={"Enter expire time in minutes"}
                value={expireTime}
                onChange={(e) => setExpireTime(e.target.value)}
              />
              <Button>Create</Button>
            </div>
          </div>
        </div>
        <div className={styles.seventhContainer}>
          <div className={styles.seventhText}>
            <p className={styles.firstSeventhText}>Website</p>
            <p className={styles.secondSeventhText}>
              Easy payment integration on your website
            </p>
          </div>
          <div className={styles.seventhContent}>
            <pre className={styles.seventhContentText}>
              {`const [createInvoice, { loading: loadingInvoice, error: errorInvoice }] =
  useMutation(CREATE_INVOICE_MUTATION, {
    context: {
      headers: {
        "Api-Key": "YOUR-API-KEY",
      },
    },
    onCompleted: (data) => {
      window.open(
        \`https://vortexonline.xyz/payment/invoice/\${data.createInvoice.invoice.invoiceNumber}\`,
        "_blank"
      );
    },
  })`}
            </pre>
          </div>
        </div>
      </div>
    </>
  )
}

export default VortexPaymentPage
