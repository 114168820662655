import { useState } from "react"
import { useMutation } from "@apollo/client"
import { CHECK_AND_SEND_EMAIL_OTP } from "../apollo/graphql/Mutation/checkAndSendEmailOtp"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/EmailVerification.module.css"

const EmailVerification = ({ onNext }) => {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)
  const [checkAndSendEmailOtp, { loading, error }] = useMutation(
    CHECK_AND_SEND_EMAIL_OTP
  )

  const handleVerifyEmail = async (
    userRegistered = false,
    initialError = null
  ) => {
    try {
      const { data } = await checkAndSendEmailOtp({
        variables: { email, userRegistered },
      })

      const result = data?.checkAndSendEmailOtp

      if (result?.success) {
        setErrorMessage(null)
        onNext(email, initialError)
      } else if (
        result?.errors &&
        result.errors.includes("Email is already registered")
      ) {
        if (!userRegistered) {
          await handleVerifyEmail(true, result.errors[0])
        } else {
          setErrorMessage(result.errors[0])
          onNext(email, result.errors[0])
        }
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
      } else {
        const unknownError = "An unknown error occurred."
        setErrorMessage(unknownError)
      }
    } catch (e) {
      const unexpectedError = "An unexpected error occurred. Please try again."
      setErrorMessage(unexpectedError)
    }
  }

  return (
    <div className={styles.container}>
      <Input
        id="email"
        label="Email Address"
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={errorMessage || error?.message}
      />
      <Button onClick={() => handleVerifyEmail()} disabled={loading}>
        Next
      </Button>
    </div>
  )
}

export default EmailVerification
