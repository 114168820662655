import { gql } from "@apollo/client"

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness(
    $legalName: String!
    $registrationNumber: String!
    $dateOfIncorporate: Date!
    $address: String!
    $postalCode: String!
    $unitNumber: String
    $countryId: ID!
  ) {
    createBusiness(
      legalName: $legalName
      registrationNumber: $registrationNumber
      dateOfIncorporate: $dateOfIncorporate
      address: $address
      postalCode: $postalCode
      unitNumber: $unitNumber
      countryId: $countryId
    ) {
      success
      business {
        id
        legalName
        owner {
          id
          email
          firstName
          lastName
        }
      }
      wallet {
        id
        walletType
      }
      errors
    }
  }
`
