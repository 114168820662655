import { useState } from "react"
import { CgArrowsExchange } from "react-icons/cg"
import formatBalance from "../../utils/formatBalance"
import formatTime from "../../utils/formatTime"
import Currency from "./Currency"
import StatusBadge from "./StatusBadge"
import renderFormattedCurrency from "../../utils/renderFormattedCurrency"
import replaceTransactionType from "../../utils/replaceTransactionType"
import Modal from "./Modal"
import styles from "../styles/UI/TransactionContainer.module.css"

const TransactionContainer = ({ transaction }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const renderTransactionDetails = () => {
    switch (transaction.__typename) {
      case "CryptocurrencySendTransactionType":
      case "CryptocurrencyReceiveTransactionType":
      case "PaymentTransactionType":
        return (
          <>
            <div className={styles.gridItem} onClick={openModal}>
              {replaceTransactionType(
                transaction.__typename.replace("TransactionType", "")
              )}
            </div>
            <div className={styles.gridItem}>
              <StatusBadge
                status={
                  transaction.sendStatus ||
                  transaction.receiveStatus ||
                  transaction.paymentStatus ||
                  "N/A"
                }
              />
            </div>
            <div className={styles.gridItem}>
              <Currency currency={transaction.currency} variant="simple" />
            </div>
            <div className={styles.gridItem}>
              {renderFormattedCurrency(
                transaction.currency.currencyType,
                transaction.currency.symbol,
                formatBalance(transaction.amount)
              )}
            </div>
            <div className={styles.gridItem}>{transaction.network.name}</div>
            <div className={styles.time}>
              {formatTime(transaction.createdAt)}
            </div>
          </>
        )
      case "BusinessTransactionType":
        return (
          <>
            <div className={styles.gridItem} onClick={openModal}>
              Business
            </div>
            <div className={styles.gridItem}>{transaction.businessStatus}</div>
            <div className={styles.gridItem}>{transaction.currency.symbol}</div>
            <div className={styles.gridItem}>{transaction.amount}</div>
            <div className={styles.time}>
              {formatTime(transaction.createdAt)}
            </div>
          </>
        )
      case "SwapTransactionType":
        return (
          <>
            <div className={styles.gridItem} onClick={openModal}>
              Swap
            </div>
            <div className={styles.gridItem}>
              <StatusBadge status={transaction.swapStatus} />
            </div>
            <div className={styles.swapCurrency}>
              <Currency currency={transaction.fromCurrency} variant="simple" />
              <CgArrowsExchange className={styles.swapIcon} />
              <Currency currency={transaction.toCurrency} variant="simple" />
            </div>
            <div className={styles.time}>
              {formatTime(transaction.createdAt)}
            </div>
          </>
        )
      default:
        return <div>Unknown transaction type.</div>
    }
  }

  const renderModalContent = () => {
    switch (transaction.__typename) {
      case "CryptocurrencySendTransactionType":
        return (
          <>
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
          </>
        )
      case "CryptocurrencyReceiveTransactionType":
        return (
          <>
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
          </>
        )
      case "PaymentTransactionType":
        return (
          <>
            <p>
              <strong>Invoice:</strong> {transaction.invoice}
            </p>
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
          </>
        )
      case "BusinessTransactionType":
        return (
          <>
            <p>
              <strong>Sender Wallet:</strong> {transaction.senderWallet.id}
            </p>
            <p>
              <strong>Receiver Wallet:</strong> {transaction.receiverWallet.id}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Description:</strong> {transaction.description}
            </p>
          </>
        )
      case "SwapTransactionType":
        return (
          <>
            <p>
              <strong>From Currency:</strong> {transaction.fromCurrency.name}
            </p>
            <p>
              <strong>To Currency:</strong> {transaction.toCurrency.name}
            </p>
            <p>
              <strong>From Amount:</strong>{" "}
              {formatBalance(transaction.fromAmount)}{" "}
              {transaction.fromCurrency.symbol}
            </p>
            <p>
              <strong>To Amount:</strong> {formatBalance(transaction.toAmount)}{" "}
              {transaction.toCurrency.symbol}
            </p>
            <p>
              <strong>Exchange Rate:</strong> {transaction.exchangeRate}
            </p>
            <p>
              <strong>Fee:</strong> {formatBalance(transaction.fee)}
            </p>
          </>
        )
      default:
        return <p>Unknown transaction type.</p>
    }
  }

  return (
    <div>
      <div className={styles.transactionContainer} onClick={openModal}>
        {renderTransactionDetails()}
      </div>
      {isModalOpen && (
        <Modal title="Transaction Details" onClose={closeModal}>
          {renderModalContent()}
        </Modal>
      )}
    </div>
  )
}

export default TransactionContainer
