import { gql } from "@apollo/client"

export const ALL_COUNTRIES = gql`
  query AllCountries {
    allCountries {
      id
      name
      phoneCode
      logoUrl
    }
  }
`
