import { useState, useEffect } from "react"
import styles from "../styles/UI/Timer.module.css"

const Timer = ({ expireTime, onEnd, label }) => {
  const [timeLeft, setTimeLeft] = useState("")

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endTime = new Date(expireTime).getTime()
      const now = new Date().getTime()
      const distance = endTime - now

      if (distance < 0) {
        setTimeLeft("00:00")
        if (typeof onEnd === "function") {
          onEnd()
        }
        return
      }

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setTimeLeft(
        `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      )
    }

    const timerId = setInterval(calculateTimeLeft, 1000)

    return () => clearInterval(timerId)
  }, [expireTime])

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timerCircle}>
        <span className={styles.timerText}>{timeLeft}</span>
      </div>
      {label && <span className={styles.expireLabel}>{label}</span>}
    </div>
  )
}

export default Timer
