import { useState, useEffect } from "react"
import WalletList from "../components/WalletList"
import TotalBalance from "../assets/UI/TotalBalance"
import ActionButton from "../assets/UI/ActionButton"
import TabMenu from "../assets/UI/TabMenu"
import styles from "../assets/styles/Pages/WalletPage.module.css"
import CryptoList from "../components/CryptoList"
import FiatList from "../components/FiatList"
import TransactionList from "../components/TransactionList"

function WalletPage() {
  const [wallet, setWallet] = useState(
    JSON.parse(localStorage.getItem("wallet"))
  )

  const handleWalletSelect = (selectedWallet) => {
    localStorage.setItem("wallet", JSON.stringify(selectedWallet))
    setWallet(selectedWallet)
  }

  useEffect(() => {
    if (!wallet) {
      setWallet(null)
    }
  }, [wallet])

  if (!wallet) {
    return <WalletList onSelectWallet={handleWalletSelect} />
  }

  const walletId = wallet.id

  const tabs = [
    {
      key: "Crypto",
      label: "Crypto",
      content: <CryptoList walletId={walletId} />,
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: <FiatList walletId={walletId} />,
    },
    {
      key: "Transactions",
      label: "Transactions",
      content: <TransactionList walletId={walletId} />,
    },
    {
      key: "Tax return",
      label: "Tax return",
      content: <div>Content for Tab 3</div>,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.balance}>
        <TotalBalance />
        <div className={styles.actionButton}>
          <ActionButton action={"Send"} />
          <ActionButton action={"Receive"} />
          <ActionButton action={"Swap"} />
        </div>
      </div>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default WalletPage
