import { gql } from "@apollo/client"

export const CONVERT_ALL_CURRENCIES = gql`
  query ConvertAllCurrencies(
    $walletId: UUID!
    $targetCurrency: String
    $currencyType: String
  ) {
    convertAllCurrencies(
      walletId: $walletId
      targetCurrency: $targetCurrency
      currencyType: $currencyType
    ) {
      originalAmount
      convertedAmount
      originalCurrency {
        id
        name
        isoCode
        logoUrl
        currencyType
      }
      targetCurrency {
        symbol
        currencyType
      }
      exchangeRate
      percentChange24h
    }
  }
`
