import { gql } from "@apollo/client"

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String!
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: Date!
    $countryId: ID!
    $password: String!
    $confirmPassword: String!
  ) {
    registerUser(
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      countryId: $countryId
      password: $password
      confirmPassword: $confirmPassword
    ) {
      success
      user {
        id
        email
        firstName
        lastName
      }
      wallet {
        id
        walletType
      }
      token
      refreshToken
      errors
    }
  }
`
