import { useEffect, useState } from "react"
import Button from "../assets/UI/Button"
import Header from "../assets/UI/Header"
import Input from "../assets/UI/Input"
import LabelButton from "../assets/UI/LabelButton"
import styles from "../assets/styles/Pages/VortexTaxesPage.module.css"
import TransactionContainer from "../assets/UI/TransactionContainer"

const transactions = [
  {
    id: "qqq",
    invoice: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "Solana",
      symbol: "SOL",
      isoCode: "SOL",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png",
      currencyType: "CRYPTO",
      __typename: "CurrencyType",
    },
    amount: "10.0000000000",
    network: {
      name: "Solana",
      symbol: "SOL",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    fee: "1",
    paymentStatus: "COMPLETED",
    createdAt: "2024-08-23T12:57:00.273863+00:00",
    __typename: "PaymentTransactionType",
  },
  {
    id: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "Toncoin",
      symbol: "TON",
      isoCode: "TON",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
      currencyType: "CRYPTO",
      __typename: "CurrencyType",
    },
    amount: "111.0000000000",
    network: {
      name: "TON",
      symbol: "TON",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    receiveStatus: "COMPLETED",
    createdAt: "2024-08-22T11:11:39.984958+00:00",
    __typename: "CryptocurrencyReceiveTransactionType",
  },
  {
    id: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "US Dollar",
      symbol: "$",
      isoCode: "USD",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
      currencyType: "FIAT",
      __typename: "CurrencyType",
    },
    amount: "15000.0000000000",
    network: {
      name: "BANK",
      symbol: "BANK",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    fee: "1",
    sendStatus: "COMPLETED",
    createdAt: "2024-08-22T09:33:01.051774+00:00",
    __typename: "CryptocurrencySendTransactionType",
  },
]

const VortexTaxesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // Локальное состояние для управления значением ввода
  const [businessName, setBusinessName] = useState("")

  // Обработчик изменения поля ввода
  const handleInputChange = (event) => {
    setBusinessName(event.target.value)
  }

  return (
    <>
      <Header logoType={"Taxes"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <p>Your taxes</p>
          <p>Our responsibility</p>
        </div>
        <div className={styles.secondContainer}>
          <div className={styles.secondContent}>
            <p>Business</p>
            <div>
              <Input
                label={"Business legal name"}
                value={businessName}
                onChange={handleInputChange}
                error={""}
              />
              <LabelButton
                icon={
                  "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/SG+Singapore.png"
                }
                label={"Country"}
              >
                Singapore
              </LabelButton>
            </div>
            <Button>Create</Button>
          </div>
          <div className={styles.secondText}>
            <p className={styles.firstSecondText}>Taxes for your country</p>
            <p className={styles.secondSecondText}>
              At Vortex, we will prepare a tax return specifically for the
              country of incorporation of your business
            </p>
          </div>
        </div>
        <div className={styles.thirdContainer}>
          <div className={styles.thirdText}>
            <p className={styles.firstThirdText}>
              All transactions in one place
            </p>
            <p className={styles.secondThirdText}>
              At Vortex, we store all your transactions and calculate the price
              specifically for the currency of your country of business
              registration
            </p>
          </div>
          <div className={styles.thirdContent}>
            {transactions.map((transaction) => (
              <TransactionContainer
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default VortexTaxesPage
