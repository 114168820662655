import styles from "../styles/UI/Card.module.css"

const Card = ({ children, onClick, disabled }) => {
  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }

  return (
    <div
      className={`${styles.card} ${disabled ? styles.disabled : ""}`}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

export default Card
