import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { IoIosMenu } from "react-icons/io"
import { FaTimes } from "react-icons/fa"
import Logo from "./Logo"
import Button from "./Button"
import styles from "../styles/UI/Header.module.css"

const Header = ({ logoType }) => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1130)

  const goToPersonal = () => navigate("/")
  const goToBusiness = () => navigate("/business")
  const goToPayment = () => navigate("/vortex-payment")
  const goToTaxes = () => navigate("/taxes")
  const goToWeb3 = () => navigate("/web3")
  const goToAML = () => navigate("/aml")
  const goToRegister = () => navigate("/register")
  const goToLogin = () => navigate("/login")

  const renderButtons = (buttons) => {
    return buttons.map((button, index) => (
      <Button key={index} withBackground={false} onClick={button.action}>
        {button.label}
      </Button>
    ))
  }

  const buttons = {
    Personal: [
      { label: "Business", action: goToBusiness },
      { label: "Payment", action: goToPayment },
      { label: "Taxes", action: goToTaxes },
      { label: "Web3", action: goToWeb3 },
      { label: "AML", action: goToAML },
    ],
    Business: [
      { label: "Personal", action: goToPersonal },
      { label: "Payment", action: goToPayment },
      { label: "Taxes", action: goToTaxes },
      { label: "Web3", action: goToWeb3 },
      { label: "AML", action: goToAML },
    ],
    Payment: [
      { label: "Personal", action: goToPersonal },
      { label: "Business", action: goToBusiness },
      { label: "Taxes", action: goToTaxes },
      { label: "Web3", action: goToWeb3 },
      { label: "AML", action: goToAML },
    ],
    Taxes: [
      { label: "Personal", action: goToPersonal },
      { label: "Business", action: goToBusiness },
      { label: "Payment", action: goToPayment },
      { label: "Web3", action: goToWeb3 },
      { label: "AML", action: goToAML },
    ],
    Web3: [
      { label: "Personal", action: goToPersonal },
      { label: "Business", action: goToBusiness },
      { label: "Payment", action: goToPayment },
      { label: "Taxes", action: goToTaxes },
      { label: "AML", action: goToAML },
    ],
    AML: [
      { label: "Personal", action: goToPersonal },
      { label: "Business", action: goToBusiness },
      { label: "Payment", action: goToPayment },
      { label: "Taxes", action: goToTaxes },
      { label: "Web3", action: goToWeb3 },
    ],
  }

  const controlHeader = () => {
    if (typeof window !== "undefined" && !isMenuOpen) {
      if (window.scrollY > lastScrollY + 5) {
        setIsVisible(false)
      } else if (window.scrollY < lastScrollY - 5) {
        setIsVisible(true)
      }
      setLastScrollY(window.scrollY)
    }
  }

  const handleResize = () => {
    const isNowMobile = window.innerWidth < 1130
    setIsMobileView(isNowMobile)

    // Закрыть меню, если экран шире 1130px
    if (!isNowMobile && isMenuOpen) {
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlHeader)
      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("scroll", controlHeader)
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [lastScrollY, isMenuOpen])

  return (
    <header className={`${styles.container} ${isVisible ? "" : styles.hidden}`}>
      <div className={styles.logoButtons}>
        <div className={styles.logoContainer}>
          <Logo type={logoType} />
        </div>
        {!isMobileView && (
          <div className={styles.buttons}>
            {renderButtons(buttons[logoType] || buttons.Personal)}
          </div>
        )}
      </div>
      <div className={styles.loginButtons}>
        {!isMobileView && (
          <>
            <Button onClick={goToRegister} disabled={true}>
              Register
            </Button>
            <Button withBackground={false} onClick={goToLogin} disabled={true}>
              Login
            </Button>
          </>
        )}
        {isMobileView && (
          <IoIosMenu
            className={styles.menuIcon}
            size={30}
            onClick={() => setIsMenuOpen(true)}
          />
        )}
      </div>

      {isMenuOpen && (
        <div className={styles.fullscreenMenu}>
          <div className={styles.menuButtons}>
            <div className={styles.logoContainer}>
              <Logo type={logoType} />
            </div>
            <button
              className={styles.closeMenuButton}
              onClick={() => setIsMenuOpen(false)}
            >
              <FaTimes size={30} />
            </button>
          </div>
          <div className={styles.menuContent}>
            {renderButtons(buttons[logoType] || buttons.Personal)}
            <Button onClick={goToRegister} disabled={true}>
              Register
            </Button>
            <Button withBackground={false} onClick={goToLogin} disabled={true}>
              Login
            </Button>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
