import { gql } from "@apollo/client"

export const RESEND_PHONE_OTP = gql`
  mutation ResendPhoneOTP($phoneNumber: String!) {
    resendPhoneOtp(phoneNumber: $phoneNumber) {
      success
      errors
    }
  }
`
