import { gql } from "@apollo/client"

export const VERIFY_EMAIL_OTP = gql`
  mutation VerifyEmailOTP($email: String!, $otp: String!) {
    verifyEmailOtp(email: $email, otp: $otp) {
      success
      errors
    }
  }
`
