import { IoMdPerson } from "react-icons/io"
import { HiUserGroup } from "react-icons/hi"
import Card from "../assets/UI/Card"
import styles from "../assets/styles/Components/RegistrationTypeSelector.module.css"

const RegistrationTypeSelector = ({ onSelect }) => {
  return (
    <div className={styles.container}>
      <Card onClick={() => onSelect("personal")}>
        <IoMdPerson className={styles.icon} />
        <span className={styles.text}>Personal Account</span>
      </Card>
      <Card onClick={() => onSelect("business")}>
        <HiUserGroup className={styles.icon} />
        <span className={styles.text}>Business Account</span>
      </Card>
    </div>
  )
}

export default RegistrationTypeSelector
