import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/BusinessAddress.module.css"

function BusinessAddress({ country, onNext }) {
  const [address, setAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [unitNumber, setUnitNumber] = useState("")

  const handleNext = () => {
    // Логика валидации может быть добавлена здесь
    onNext({ address, postalCode, unitNumber })
  }

  return (
    <div className={styles.container}>
      <Input
        id="address"
        label="Address"
        type="text"
        placeholder="Enter your business address"
        icon={country.logoUrl}
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <Input
        id="postalCode"
        label="Postal Code"
        type="text"
        placeholder="Enter your postal code"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
      />
      <Input
        id="unitNumber"
        label="Unit Number"
        type="text"
        placeholder="Enter your unit number"
        value={unitNumber}
        onChange={(e) => setUnitNumber(e.target.value)}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default BusinessAddress
