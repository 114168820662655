import TabMenu from "../assets/UI/TabMenu"

function PaymentPage() {
  const tabs = [
    {
      key: "Business",
      label: "Business",
      content: 11,
    },
    {
      key: "Invoice",
      label: "Invoice",
      content: 123,
    },
    {
      key: "Static Invoice",
      label: "Static Invoice",
      content: <div>Content for Tab 3</div>,
    },
    {
      key: "All Static Invoice",
      label: "All Static Invoice",
      content: <div>Content for Tab 3</div>,
    },
  ]
  return (
    <>
      <h1>Payment</h1>
      <TabMenu tabs={tabs} />
    </>
  )
}

export default PaymentPage
