import { useState } from "react"
import styles from "../styles/UI/OTPInput.module.css"

const OTPInput = ({
  length = 6,
  onComplete,
  errorMessage,
  title,
  secondLabel,
  onSecondLabelClick,
}) => {
  const [otp, setOtp] = useState(Array(length).fill(""))
  const [hasError, setHasError] = useState(false)

  const handleChange = (e, index) => {
    const { value } = e.target
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)
      setHasError(false)
      if (value !== "" && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus()
      }
      if (newOtp.join("").length === length && value !== "") {
        onComplete(newOtp.join(""))
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus()
      }
    }
    if (e.key === "Backspace" && otp[index] !== "") {
      const newOtp = [...otp]
      newOtp[index] = ""
      setOtp(newOtp)
      setHasError(false)
    }
  }

  const handleResend = () => {
    if (onSecondLabelClick) {
      onSecondLabelClick()
    }
    setOtp(Array(length).fill(""))
    setHasError(false)
  }

  return (
    <div className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.otpContainer}>
        {otp.map((_, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            className={`${styles.otpInput} ${errorMessage ? styles.error : ""}`}
            value={otp[index]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>
      <div className={styles.errorAndSecondLabel}>
        {errorMessage && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}
        <button className={styles.secondLabelButton} onClick={handleResend}>
          {secondLabel}
        </button>
      </div>
    </div>
  )
}

export default OTPInput
