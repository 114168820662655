import { useNavigate } from "react-router-dom"
import formatBalance from "../../utils/formatBalance"
import renderFormattedCurrency from "../../utils/renderFormattedCurrency"
import styles from "../styles/UI/Currency.module.css"

const Currency = ({
  originalAmount,
  convertedAmount,
  currency = {},
  targetCurrency = {},
  exchangeRate,
  percentChange24h,
  isSelected = true,
  action,
  variant = "detailed",
  onClick,
}) => {
  const navigate = useNavigate()

  const { id, logoUrl, name, isoCode } = currency || {}
  const { symbol, currencyType } = targetCurrency || {}

  const handleCurrencyClick = () => {
    if (onClick) {
      onClick(id)
    }
    if (action) {
      navigate(`/${action}/${isoCode}`)
    }
  }

  const formattedBalance =
    originalAmount !== undefined ? formatBalance(originalAmount) : ""
  const formattedExchangeRate =
    exchangeRate !== undefined ? formatBalance(exchangeRate) : ""
  const formattedPercentChange24h =
    percentChange24h !== undefined ? formatBalance(percentChange24h) : ""
  const formattedConvertedBalance =
    convertedAmount !== undefined ? formatBalance(convertedAmount) : ""

  const currencyClass = isSelected ? "" : styles.currencyNoSelected

  const cursorStyle = onClick || action ? "pointer" : "auto"

  return variant === "simple" ? (
    <div
      className={`${styles.currency} ${currencyClass}`}
      onClick={handleCurrencyClick}
      style={{ cursor: cursorStyle }}
    >
      {logoUrl && (
        <img
          src={logoUrl}
          alt={`${name} logo`}
          className={styles.currencyLogo}
        />
      )}
      <div className={styles.currencyContainerSimple}>
        {isoCode && (
          <span className={styles.currencyCodeSimple}>{isoCode}</span>
        )}
        {name && <span className={styles.currencyNameSimple}>{name}</span>}
      </div>
    </div>
  ) : (
    <div
      className={`${styles.currency} ${currencyClass}`}
      onClick={handleCurrencyClick}
      style={{ cursor: cursorStyle }}
    >
      {logoUrl && (
        <img
          src={logoUrl}
          alt={`${name} logo`}
          className={styles.currencyLogo}
        />
      )}
      <div className={styles.currencyContainer}>
        <div className={styles.currencyName}>
          {name && <span className={styles.currencyTitle}>{name}</span>}

          {(formattedExchangeRate || formattedPercentChange24h) && (
            <div className={styles.currencyExchangeRate}>
              {formattedExchangeRate && (
                <span className={styles.exchangeRate}>
                  {renderFormattedCurrency(
                    currencyType,
                    symbol,
                    formattedExchangeRate
                  )}
                </span>
              )}
              {formattedPercentChange24h && (
                <span
                  className={
                    formattedPercentChange24h >= 0
                      ? styles.percentChangePositive
                      : styles.percentChangeNegative
                  }
                >
                  {formattedPercentChange24h > 0
                    ? `+${formattedPercentChange24h}%`
                    : `${formattedPercentChange24h}%`}
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.currencyBalance}>
          {formattedBalance && (
            <span className={styles.balance}>
              {formattedBalance} {isoCode}
            </span>
          )}
          {formattedConvertedBalance && (
            <span className={styles.convertedBalance}>
              {renderFormattedCurrency(
                currencyType,
                symbol,
                formattedConvertedBalance
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Currency
