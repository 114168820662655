import { gql } from "@apollo/client"

export const CHECK_AND_SEND_EMAIL_OTP = gql`
  mutation CheckAndSendEmailOTP($email: String!, $userRegistered: Boolean!) {
    checkAndSendEmailOtp(email: $email, userRegistered: $userRegistered) {
      success
      userExists
      errors
    }
  }
`
