import { gql } from "@apollo/client"

export const CHECK_AND_SEND_PHONE_OTP = gql`
  mutation CheckAndSendPhoneOTP($phoneNumber: String!) {
    checkAndSendPhoneOtp(phoneNumber: $phoneNumber) {
      success
      phoneNumberExists
      errors
    }
  }
`
