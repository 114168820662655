import { useState } from "react"
import { useMutation } from "@apollo/client"
import { VERIFY_EMAIL_OTP } from "../apollo/graphql/Mutation/verifyEmailOtp"
import { RESEND_EMAIL_OTP } from "../apollo/graphql/Mutation/resendEmailOtp"
import OTPInput from "../assets/UI/OTPInput"
import styles from "../assets/styles/Components/OTPEmailVerification.module.css"

const OTPEmailVerification = ({ email, onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP)
  const [resendEmailOtp] = useMutation(RESEND_EMAIL_OTP)

  const handleOTPComplete = async (otp) => {
    try {
      const { data } = await verifyEmailOtp({
        variables: { email, otp }, // Передаем email как переменную
      })

      const result = data?.verifyEmailOtp

      if (result?.success) {
        setErrorMessage(null)
        onSuccess() // Вызов функции успеха при успешной верификации OTP
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
      } else {
        setErrorMessage("An unknown error occurred.")
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred. Please try again.")
      console.error("Verification error:", e.message)
    }
  }

  const handleResendOTP = async () => {
    try {
      const { data } = await resendEmailOtp({
        variables: { email }, // Передаем email как переменную
      })

      const result = data?.resendEmailOtp

      if (result?.success) {
        setErrorMessage(null)
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
      } else {
        setErrorMessage("An unknown error occurred.")
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred. Please try again.")
      console.error("Resend error:", e.message)
    }
  }

  return (
    <div className={styles.container}>
      <OTPInput
        length={6}
        onComplete={handleOTPComplete}
        errorMessage={errorMessage}
        title="Check your email"
        secondLabel="Resend Code"
        onSecondLabelClick={handleResendOTP}
      />
    </div>
  )
}

export default OTPEmailVerification
