import { gql } from "@apollo/client"

export const USER_BY_EMAIL = gql`
  query UserByEmail($email: String!) {
    userSummary(email: $email) {
      firstName
      lastName
    }
  }
`
