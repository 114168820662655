import styles from "../styles/UI/Button.module.css"

const Button = ({
  onClick,
  children,
  disabled,
  icon: Icon,
  withBackground = true,
}) => {
  const buttonClass = disabled
    ? `${styles.button} ${styles.buttonDisabled}`
    : withBackground
    ? `${styles.button} ${styles.withBackground}`
    : `${styles.button} ${styles.noBackground}`

  return (
    <button onClick={onClick} className={buttonClass} disabled={disabled}>
      {children}
      {Icon && <Icon className={styles.icon} />}
    </button>
  )
}

export default Button
