import React from "react"
import { useQuery } from "@apollo/client"
import { TOTAL_CONVERTED_BALANCE } from "../../apollo/graphql/Query/totalConvertedBalance"
import styles from "../styles/UI/TotalBalance.module.css"
import formatBalance from "../../utils/formatBalance"

const TotalBalance = () => {
  const wallet = JSON.parse(localStorage.getItem("wallet"))
  const walletId = wallet?.id

  const { loading, error, data } = useQuery(TOTAL_CONVERTED_BALANCE, {
    variables: { walletId },
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error fetching balance: {error.message}</p>

  const balance = data?.totalConvertedBalance?.totalConvertedBalance
  const currencySymbol = data?.totalConvertedBalance?.targetCurrency?.symbol
  const currencyType = data?.totalConvertedBalance?.targetCurrency?.currencyType

  return (
    <div className={styles.totalBalanceContainer}>
      <div className={styles.label}>Total Balance</div>
      <div className={styles.balance}>
        {currencyType === "FIAT" ? (
          <>
            {currencySymbol}
            {formatBalance(balance)}
          </>
        ) : (
          <>
            {formatBalance(balance)} {currencySymbol}
          </>
        )}
      </div>
    </div>
  )
}

export default TotalBalance
