import { useState } from "react"
import { useMutation } from "@apollo/client"
import { useNavigate, useLocation } from "react-router-dom"
import { LOGIN_USER_MUTATION } from "../apollo/graphql/Mutation/loginUser"
import OTPEmailVerification from "../components/OTPEmailVerification"
import LoginInputs from "../components/LoginInputs"

const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState(location.state?.email || "")
  const [password, setPassword] = useState("") // Хранение пароля
  const [otpVerified, setOtpVerified] = useState(false)
  const [twoFactorAuthenticated, setTwoFactorAuthenticated] = useState(
    location.state?.twoFactorAuthenticated || false
  )
  const isAddingBusiness = location.state?.isAddingBusiness || false

  const [loginUser] = useMutation(LOGIN_USER_MUTATION)

  const handleOtpSuccess = async () => {
    try {
      const { data } = await loginUser({
        variables: { email, password }, // Используем сохраненные email и пароль
      })

      if (data.loginUser.success) {
        // Сохраняем токены в localStorage
        localStorage.setItem("token", data.loginUser.token)
        localStorage.setItem("refreshToken", data.loginUser.refreshToken)

        if (isAddingBusiness) {
          navigate("/register", { state: { isAddingBusiness: true } }) // Перенаправляем на создание бизнеса
        } else {
          navigate("/wallet") // Успешный вход, переход в /wallet
        }
      } else {
        console.error("Login failed:", data.loginUser.errors)
      }
    } catch (error) {
      console.error("Login error:", error)
    }
  }

  const handleLogin = async () => {
    try {
      const { data } = await loginUser({
        variables: { email, password }, // Используем сохраненные email и пароль
      })

      if (data.loginUser.success) {
        // Сохраняем токены в localStorage
        localStorage.setItem("token", data.loginUser.token)
        localStorage.setItem("refreshToken", data.loginUser.refreshToken)

        if (isAddingBusiness) {
          navigate("/register", { state: { isAddingBusiness: true } }) // Перенаправляем на создание бизнеса
        } else {
          navigate("/wallet") // Успешный вход, переход в /wallet
        }
      } else {
        console.error("Login failed:", data.loginUser.errors)
      }
    } catch (error) {
      console.error("Login error:", error)
    }
  }

  return (
    <div>
      <h1>Login</h1>
      {!otpVerified ? (
        <LoginInputs
          email={email}
          setEmail={setEmail}
          setPassword={setPassword} // Передаем функцию для сохранения пароля
          setTwoFactorAuthenticated={setTwoFactorAuthenticated} // Передаем функцию для изменения twoFactorAuthenticated
          onSuccess={(success) => {
            if (
              success &&
              twoFactorAuthenticated &&
              email === location.state?.email
            ) {
              handleLogin() // Пропускаем 2FA и логинимся, если нет ошибок и 2FA не требуется
            } else if (success) {
              setOtpVerified(true) // Переход к 2FA, если проверка успешна, но 2FA требуется
            }
          }}
        />
      ) : (
        <OTPEmailVerification email={email} onSuccess={handleOtpSuccess} />
      )}
    </div>
  )
}

export default LoginPage
