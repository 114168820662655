import { useState } from "react"
import { useQuery } from "@apollo/client"
import { ALL_TRANSACTIONS } from "../apollo/graphql/Query/allTransactions"
import TransactionContainer from "../assets/UI/TransactionContainer"
import styles from "../assets/styles/Components/TransactionList.module.css"

const TransactionList = ({ walletId }) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [transactionTypes, setTransactionTypes] = useState([
    "SEND",
    "RECEIVE",
    "PAYMENT",
    "SWAP",
    "BUSINESS",
  ])
  const [currencyType, setCurrencyType] = useState(null)
  const [currencyIsoCode, setCurrencyIsoCode] = useState(null)

  const { loading, error, data } = useQuery(ALL_TRANSACTIONS, {
    variables: {
      walletId,
      fromDate,
      toDate,
      transactionTypes,
      currencyType,
      currencyIsoCode,
    },
  })

  if (loading) return <p>Loading transactions...</p>
  if (error) return <p>Error: {error.message}</p>

  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((groups, transaction) => {
      const date = new Date(transaction.createdAt).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })

      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(transaction)
      return groups
    }, {})
  }

  const groupedTransactions = groupTransactionsByDate(data.allTransactions)

  return (
    <>
      {Object.keys(groupedTransactions).length > 0 ? (
        <div className={styles.transactionsContainer}>
          {Object.keys(groupedTransactions).map((date) => (
            <div key={date}>
              <div className={styles.dateHeader}>{date}</div>
              <div className={styles.transactions}>
                {groupedTransactions[date].map((transaction) => (
                  <TransactionContainer
                    key={transaction.id}
                    transaction={transaction}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No transactions found.</p>
      )}
    </>
  )
}

export default TransactionList
