import { NavLink } from "react-router-dom"
import Logo from "./Logo"
import styles from "../styles/UI/Sidebar.module.css"

const Sidebar = ({ walletType }) => {
  if (walletType !== "BUSINESS" && walletType !== "PERSONAL") {
    throw new Error(
      `Invalid walletType: ${walletType}. Expected "BUSINESS" or "PERSONAL".`
    )
  }

  const businessLinks = [
    { name: "Wallet", path: "/wallet" },
    { name: "Payment", path: "/payment" },
    { name: "Integration", path: "/integration" },
    { name: "Statistic", path: "/statistic" },
    { name: "Settings", path: "/settings" },
  ]

  const personalLinks = [
    { name: "Wallet", path: "/wallet" },
    { name: "Transactions", path: "/transactions" },
    { name: "Profile", path: "/profile" },
    { name: "Support", path: "/support" },
  ]

  const links = walletType === "BUSINESS" ? businessLinks : personalLinks

  return (
    <div className={styles.sidebar}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <nav className={styles.navLinks}>
        {links.map((link) => (
          <NavLink
            to={link.path}
            key={link.name}
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
          >
            {link.name}
          </NavLink>
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
