import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { CHECK_AND_SEND_PHONE_OTP } from "../apollo/graphql/Mutation/checkAndSendPhoneOtp"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/PhoneVerification.module.css"

function PhoneVerification({ onNext, country }) {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)
  const [checkAndSendPhoneOtp, { loading, error }] = useMutation(
    CHECK_AND_SEND_PHONE_OTP
  )

  useEffect(() => {
    console.log("Country data:", country) // Проверяем, что данные о стране передаются
  }, [country])

  const handleVerifyPhone = async () => {
    try {
      const fullPhoneNumber = `${country.phoneCode}${phoneNumber}`
      const { data } = await checkAndSendPhoneOtp({
        variables: { phoneNumber: fullPhoneNumber },
      })

      const result = data?.checkAndSendPhoneOtp

      if (result?.success) {
        setErrorMessage(null)
        onNext(fullPhoneNumber)
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
        console.log("Errors:", result.errors)
      } else {
        setErrorMessage("An unknown error occurred.")
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred. Please try again.")
      console.error("Verification error:", e.message)
    }
  }

  return (
    <div className={styles.container}>
      <Input
        id="phone"
        label="Phone number"
        type="text"
        placeholder="Enter your phone number"
        icon={country.logoUrl}
        prefix={country.phoneCode}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        error={errorMessage || error?.message}
        className={styles.phoneInput}
      />
      <Button onClick={handleVerifyPhone} disabled={loading}>
        Next
      </Button>
    </div>
  )
}

export default PhoneVerification
