import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/PersonalInfo.module.css"

function PersonalInfo({ onNext }) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")

  const handleNext = () => {
    // Логика валидации может быть добавлена здесь
    onNext({ firstName, lastName, dateOfBirth })
  }

  return (
    <div className={styles.container}>
      <Input
        id="firstName"
        label="First Name"
        type="text"
        placeholder="Enter your first name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <Input
        id="lastName"
        label="Last Name"
        type="text"
        placeholder="Enter your last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <Input
        id="dateOfBirth"
        label="Date of Birth"
        type="date"
        placeholder="Enter your date of birth"
        value={dateOfBirth}
        onChange={(e) => setDateOfBirth(e.target.value)}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default PersonalInfo
