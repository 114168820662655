import { SlArrowRight } from "react-icons/sl"
import styles from "../styles/UI/LabelButton.module.css"

const LabelButton = ({
  icon,
  label,
  onClick,
  disabled,
  arrow,
  active = true,
  size = "normal",
  children,
}) => {
  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <button
        className={`${styles.labelButton} ${disabled ? styles.disabled : ""} ${
          !active ? styles.inactive : ""
        } ${size === "small" ? styles.small : ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        <div
          className={`${styles.content} ${
            size === "small" ? styles.small : ""
          }`}
        >
          {icon && <img src={icon} alt="icon" className={styles.icon} />}
          {children}
        </div>
        {arrow && <SlArrowRight />}
      </button>
    </div>
  )
}

export default LabelButton
