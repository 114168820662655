import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import RegisterPage from "./pages/RegisterPage"
import WalletPage from "./pages/WalletPage"
import Layout from "./Layout"
import CurrencyPage from "./pages/CurrencyPage"
import LoginPage from "./pages/LoginPage"
import PaymentPage from "./pages/PaymentPage"
import PrivateRoute from "./routes/PrivateRoute"
import VortexPersonalPage from "./pages/VortexPersonalPage"
import VortexBusinessPage from "./pages/VortexBusinessPage"
import VortexPaymentPage from "./pages/VortexPaymentPage"
import VortexTaxesPage from "./pages/VortexTaxesPage"
import VortexWeb3Page from "./pages/VortexWeb3Page"
import VortexAMLPage from "./pages/VortexAMLPage"

function App() {
  const wallet = JSON.parse(localStorage.getItem("wallet"))
  const walletType = wallet?.walletType || "PERSONAL"

  return (
    <Router>
      <Routes>
        <Route path="/" element={<VortexPersonalPage />} />
        <Route path="/business" element={<VortexBusinessPage />} />
        <Route path="/vortex-payment" element={<VortexPaymentPage />} />
        <Route path="/taxes" element={<VortexTaxesPage />} />
        <Route path="/web3" element={<VortexWeb3Page />} />
        <Route path="/aml" element={<VortexAMLPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout walletType={walletType} />
            </PrivateRoute>
          }
        >
          <Route path="wallet" element={<WalletPage />} />
          <Route path="currency/:isoCode" element={<CurrencyPage />} />
          <Route path="payment" element={<PaymentPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
