import { gql } from "@apollo/client"

export const RESEND_EMAIL_OTP = gql`
  mutation ResendEmailOTP($email: String!) {
    resendEmailOtp(email: $email) {
      success
      errors
    }
  }
`
