import React from "react"
import { FaTimes } from "react-icons/fa"
import styles from "../styles/UI/Modal.module.css"

const Modal = ({
  title,
  onClose,
  children,
  firstBlockSpacing = false,
  width = "70vh",
  height = "50vh",
}) => {
  return (
    <div className={styles.modalOverlay}>
      <div
        className={styles.modalContent}
        style={{ width: width, maxHeight: height }}
      >
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className={styles.body}>
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className={`${styles.childBlock} ${
                index === 0 && firstBlockSpacing ? styles.firstBlock : ""
              }`}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Modal
