import { FiArrowUpRight, FiArrowDown } from "react-icons/fi"
import { CgArrowsExchange } from "react-icons/cg"
import styles from "../styles/UI/ActionButton.module.css"

const ActionButton = ({ onClick, disabled, action }) => {
  const getIcon = () => {
    switch (action) {
      case "Send":
        return <FiArrowUpRight />
      case "Receive":
        return <FiArrowDown />
      case "Swap":
        return <CgArrowsExchange />
      default:
        return null
    }
  }

  return (
    <button
      className={`${styles.button} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.iconContainer}>
        <div className={styles.icon}>{getIcon()}</div>
      </div>
      <div>{action}</div>
    </button>
  )
}

export default ActionButton
