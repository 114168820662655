import { gql } from "@apollo/client"

export const VERIFY_USER = gql`
  mutation VerifyUser($email: String!, $password: String!) {
    verifyUser(email: $email, password: $password) {
      success
      errors
    }
  }
`
