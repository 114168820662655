import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/PasswordInput.module.css"

function PasswordInput({ onNext }) {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState(null)

  const handleNext = () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match")
      return
    }

    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long")
      return
    }

    setErrorMessage(null)

    console.log("Password submission:", { password, confirmPassword })

    // Передаем данные паролей в родительский компонент
    const success = onNext({ password, confirmPassword })
    console.log("Password submission success:", success)
  }

  return (
    <div className={styles.container}>
      <Input
        id="password"
        label="Password"
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={errorMessage}
      />
      <Input
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        placeholder="Confirm your password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={errorMessage}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default PasswordInput
