import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import Currency from "../assets/UI/Currency"
import Header from "../assets/UI/Header"
import formatBalance from "../utils/formatBalance"
import Logo from "../assets/UI/Logo"
import Button from "../assets/UI/Button"
import LabelButton from "../assets/UI/LabelButton"
import Input from "../assets/UI/Input"
import TabMenu from "../assets/UI/TabMenu"
import { useNavigate } from "react-router-dom"
import styles from "../assets/styles/Pages/VortexPersonalPage.module.css"

const cryptoCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/bitcoin.png",
    name: "Bitcoin",
    isoCode: "BTC",
    symbol: "$",
    originalAmount: 1,
    convertedAmount: 59037,
    exchangeRate: 59037,
    percentChange24h: 2.5,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png",
    name: "Solana",
    isoCode: "SOL",
    symbol: "$",
    originalAmount: 150,
    convertedAmount: 22500,
    exchangeRate: 150,
    percentChange24h: 0.8,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Ethereum+(ETH).png",
    name: "Ethereum",
    isoCode: "ETH",
    symbol: "$",
    originalAmount: 10,
    convertedAmount: 27170,
    exchangeRate: 2717,
    percentChange24h: -1.2,
    currencyType: "Crypto",
  },
]

const fiatCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
    name: "USD",
    isoCode: "USD",
    symbol: "$",
    originalAmount: 1000,
    convertedAmount: 1000,
    exchangeRate: 1,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/SG+Singapore.png",
    name: "SGD",
    isoCode: "SGD",
    symbol: "$",
    originalAmount: 1500,
    convertedAmount: 1155,
    exchangeRate: 0.77,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/EU+European+Union.png",
    name: "EUR",
    isoCode: "EUR",
    symbol: "$",
    originalAmount: 900,
    convertedAmount: 990,
    exchangeRate: 1.1,
    currencyType: "Fiat",
  },
]

const nftCurrencies = [
  {
    name: "Broken Face",
    tokenStandard: "ERC111",
    tokenId: 4611,
    blockchain: "Ethereum",
    nftNumber: "#03",
    imageUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/NFT.png",
  },
]

const generateRandomChar = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#№$%^:?&*-_+="
  return chars[Math.floor(Math.random() * chars.length)]
}

const animateText = (targetText, setCurrentText) => {
  const length = targetText.length
  let currentStep = 0

  const interval = setInterval(() => {
    currentStep++
    const placeholder = targetText
      .split("")
      .map((char, index) => {
        if (index < currentStep) return char
        return generateRandomChar()
      })
      .join("")

    setCurrentText(placeholder)

    if (currentStep === length) {
      clearInterval(interval)
    }
  }, 300 / length)

  return () => clearInterval(interval)
}

const tabs = [
  {
    key: "Crypto",
    label: "Crypto",
    content: (
      <div className={styles.fourthContentCrypto}>
        {cryptoCurrencies.map((currency, index) => (
          <Currency
            key={index}
            originalAmount={currency.originalAmount}
            convertedAmount={currency.convertedAmount}
            currency={currency}
            targetCurrency={{
              symbol: currency.symbol,
              currencyType: currency.currencyType,
            }}
            exchangeRate={currency.exchangeRate}
            percentChange24h={currency.percentChange24h}
          />
        ))}
      </div>
    ),
  },
  {
    key: "NFT",
    label: "NFT",
    content: (
      <div className={styles.fourthContentCrypto}>
        {cryptoCurrencies.map((currency, index) => (
          <Currency
            key={index}
            originalAmount={currency.originalAmount}
            convertedAmount={currency.convertedAmount}
            currency={currency}
            targetCurrency={{
              symbol: currency.symbol,
              currencyType: currency.currencyType,
            }}
            exchangeRate={currency.exchangeRate}
            percentChange24h={currency.percentChange24h}
          />
        ))}
      </div>
    ),
  },
]

const VortexPersonalPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [currentType, setCurrentType] = useState("Crypto")
  const [currentText, setCurrentText] = useState("Crypto")
  const [currenciesToShow, setCurrenciesToShow] = useState(cryptoCurrencies)
  const [totalBalance, setTotalBalance] = useState(0)
  const [paymentValue, setPaymentValue] = useState("")
  const handlePaymentRightButtonClick = () => {
    setPaymentValue("150")
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentType((prevType) => {
        switch (prevType) {
          case "Crypto":
            animateText("Fiat", setCurrentText)
            setCurrenciesToShow(fiatCurrencies)
            return "Fiat"
          case "Fiat":
            animateText("NFT", setCurrentText)
            setCurrenciesToShow(nftCurrencies)
            return "NFT"
          case "NFT":
            animateText("Crypto", setCurrentText)
            setCurrenciesToShow(cryptoCurrencies)
            return "Crypto"
          default:
            return "Crypto"
        }
      })
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (currentType !== "NFT") {
      const total = currenciesToShow.reduce(
        (sum, currency) => sum + currency.convertedAmount,
        0
      )
      setTotalBalance(total)
    }
  }, [currenciesToShow, currentType])

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  }

  return (
    <>
      <Header logoType={"Personal"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <div className={styles.firstText}>
            <p>
              Your{" "}
              <motion.span
                key={currentType}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={styles.typedText}
              >
                {currentText}
              </motion.span>
            </p>
            <p>In one place</p>
          </div>
          <div className={styles.firstContent}>
            {currentType !== "NFT" && (
              <motion.div
                className={styles.firstContentTextContainer}
                initial="hidden"
                animate="visible"
                variants={containerVariants}
              >
                <motion.p
                  className={styles.firstContentText}
                  variants={itemVariants}
                >
                  Total Balance
                </motion.p>
                <motion.p
                  className={styles.secondContentText}
                  variants={itemVariants}
                >
                  ${formatBalance(totalBalance)}
                </motion.p>
                <motion.p
                  className={styles.firstContentCurrencyType}
                  variants={itemVariants}
                >
                  {currentType}
                </motion.p>
              </motion.div>
            )}
            {currenciesToShow.map((currency, index) => {
              if (currentType === "NFT") {
                return (
                  <motion.div
                    key={index}
                    className={styles.nftContainer}
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                  >
                    <img src={currency.imageUrl} alt={currency.name} />
                    <div className={styles.nftTextContainer}>
                      <div className={styles.nftNameContainer}>
                        <motion.p
                          className={styles.nftName}
                          variants={itemVariants}
                        >
                          {currency.name}
                        </motion.p>
                        <motion.p
                          className={styles.nftNumber}
                          variants={itemVariants}
                        >
                          {currency.nftNumber}
                        </motion.p>
                      </div>
                      <div className={styles.nftSecondTextContainer}>
                        <motion.div
                          className={styles.nftText}
                          variants={itemVariants}
                        >
                          <p>Token standard</p>
                          <p>Token ID</p>
                          <p>Blockchain</p>
                        </motion.div>
                        <motion.div
                          className={styles.nftSecondText}
                          variants={itemVariants}
                        >
                          <p>{currency.tokenStandard}</p>
                          <p>{currency.tokenId}</p>
                          <p>{currency.blockchain}</p>
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                )
              } else {
                return (
                  <motion.div
                    key={index}
                    initial="hidden"
                    animate="visible"
                    variants={itemVariants}
                  >
                    <Currency
                      originalAmount={currency.originalAmount}
                      convertedAmount={currency.convertedAmount}
                      currency={currency}
                      targetCurrency={{
                        symbol: currency.symbol,
                        currencyType: currency.currencyType,
                      }}
                      exchangeRate={currency.exchangeRate}
                      percentChange24h={currency.percentChange24h}
                    />
                  </motion.div>
                )
              }
            })}
          </div>
        </div>
        <div className={styles.secondContainer}>
          <div className={styles.secondText}>
            <div className={styles.secondTextLogo}>
              <Logo type={"Payment"} />
            </div>
            <p className={styles.firstSecondText}>Worldwide payments</p>
            <p className={styles.secondSecondText}>
              Pay worldwide via Vortex Payment with 0% fee
            </p>
            <Button
              onClick={() => {
                navigate("/vortex-payment")
              }}
            >
              Learn more
            </Button>
          </div>
          <div className={styles.secondContent}>
            <LabelButton
              icon={
                "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png"
              }
            >
              Solana
            </LabelButton>
            <Input label={"Address"} placeholder={"Address"}></Input>
            <LabelButton label={"Network"} arrow={true}>
              Solana
            </LabelButton>
            <Input
              label={"Amount"}
              type={"Number"}
              placeholder={"0"}
              secondaryLabel={"150 SOL"}
              rightButtonLabel={"MAX"}
              rightText={"SOL"}
              value={paymentValue}
              onChange={(e) => setPaymentValue(e.target.value)}
              onRightButtonClick={handlePaymentRightButtonClick}
            />
          </div>
        </div>
        <div className={styles.thirdContainer}>
          <div className={styles.thirdText}>
            <p className={styles.firstThirdText}>Swap</p>
            <p className={styles.secondThirdText}>
              Swap your selected currencies instantly with our secure and
              easy-to-use swapping feature
            </p>
          </div>
          <div className={styles.thirdContent}>
            <div className={styles.swapContentContainer}>
              <div className={styles.swapContent}>
                <Currency
                  variant="simple"
                  currency={{
                    isoCode: "USD",
                    name: "US Dollar",
                    logoUrl:
                      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
                  }}
                />
                <div className={styles.firstSwapContentAmountContainer}>
                  <p className={styles.firstSwapContentAmount}>100</p>
                  <p className={styles.firstSwapContentSecondAmount}>$100</p>
                </div>
              </div>
              <div className={styles.swapContent}>
                <Currency
                  variant="simple"
                  currency={{
                    isoCode: "TON",
                    name: "Toncoin",
                    logoUrl:
                      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
                  }}
                />
                <p className={styles.secondSwapContentAmount}>18,55</p>
              </div>
            </div>
            <div className={styles.swapContentButton}>
              <Button withBackground={false}>Swap</Button>
            </div>
          </div>
        </div>
        <div className={styles.fourthContainer}>
          <div className={styles.fourthText}>
            <div className={styles.fourthTextLogo}>
              <Logo type={"Web3"} />
            </div>
            <p>
              Cryptocurrency wallet for those who value unparalleled security
              and true decentralization
            </p>
            <Button
              onClick={() => {
                navigate("/web3")
              }}
            >
              Learn more
            </Button>
          </div>
          <div className={styles.fourthContent}>
            <div className={styles.fourthContentAmountContainer}>
              <p className={styles.fourthContentAmount}>$108 707</p>
            </div>
            <TabMenu tabs={tabs} />
          </div>
        </div>
      </div>
    </>
  )
}

export default VortexPersonalPage
