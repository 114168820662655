import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { USER_BY_EMAIL } from "../apollo/graphql/Query/userSummary"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/AccountExists.module.css"

const AccountExists = ({ email }) => {
  const navigate = useNavigate()
  const [getUserByEmail, { loading, data, error }] = useLazyQuery(USER_BY_EMAIL)

  useEffect(() => {
    if (email) {
      getUserByEmail({ variables: { email } })
    }
  }, [email, getUserByEmail])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const user = data?.userSummary

  const handleLoginRedirect = () => {
    navigate("/login", {
      state: {
        email,
        isAddingBusiness: true,
        twoFactorAuthenticated: true,
      },
    })
  }

  return (
    <div className={styles.container}>
      {user ? (
        <>
          <h2>Account Already Registered</h2>
          <p>
            The account with email <strong>{email}</strong> is already
            registered.
          </p>
          <p>
            Name:{" "}
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </p>
          <Button onClick={handleLoginRedirect}>Login</Button>
        </>
      ) : (
        <p>No user found for the email: {email}</p>
      )}
    </div>
  )
}

export default AccountExists
