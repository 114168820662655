import { useQuery } from "@apollo/client"
import { CONVERT_ALL_CURRENCIES } from "../apollo/graphql/Query/convertAllCurrencies"
import Currency from "../assets/UI/Currency"
import styles from "../assets/styles/Components/FiatList.module.css"

const FiatList = ({ walletId }) => {
  const { loading, error, data } = useQuery(CONVERT_ALL_CURRENCIES, {
    variables: {
      walletId,
      currencyType: "FIAT",
    },
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error fetching fiats: {error.message}</p>

  return (
    <div className={styles.container}>
      {data.convertAllCurrencies.map((currencyData) => (
        <Currency
          key={currencyData.originalCurrency.id}
          originalAmount={currencyData.originalAmount}
          convertedAmount={currencyData.convertedAmount}
          currency={currencyData.originalCurrency}
          targetCurrency={currencyData.targetCurrency}
          exchangeRate={currencyData.exchangeRate}
          percentChange24h={currencyData.percentChange24h}
          action={"currency"}
        />
      ))}
    </div>
  )
}

export default FiatList
