import Sidebar from "./assets/UI/Sidebar"
import { Outlet } from "react-router-dom"
import styles from "./Layout.module.css"

const Layout = ({ walletType }) => {
  return (
    <div className={styles.layout}>
      <Sidebar walletType={walletType} />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
