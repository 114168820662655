import { useEffect } from "react"
import { ReactTyped } from "react-typed"
import Header from "../assets/UI/Header"
import Lottie from "react-lottie"
import animationData from "../assets/Lottie/AI.json"
import styles from "../assets/styles/Pages/VortexAMLPage.module.css"

const VortexAMLPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <>
      <Header logoType={"AML"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <ReactTyped
            strings={["Vortex Anti-Money Laundering AI"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
        <div className={styles.secondContainer}>
          <div className={styles.secondText}>
            <p className={styles.firstSecondText}>
              Verification of each transaction
            </p>
            <p className={styles.secondSecondText}>
              Every transaction that passes through Vortex is verified for
              security and legitimacy using our advanced Vortex AML AI system
              <br />
              We analyze each transaction in real-time using data from the
              blockchain to track the origin of funds and identify suspicious
              activity
              <br />
              Through integration with global databases and automated
              algorithms, Vortex AML AI helps prevent fraud and money
              laundering, keeping your business safe and compliant with
              regulatory requirements
            </p>
          </div>
          <div className={styles.secondContent}>
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>
        </div>
      </div>
    </>
  )
}

export default VortexAMLPage
