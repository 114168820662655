import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/BusinessInfo.module.css"

function BusinessInfo({ onNext }) {
  const [legalName, setLegalName] = useState("")
  const [registrationNumber, setRegistrationNumber] = useState("")
  const [dateOfIncorporate, setDateOfIncorporate] = useState("")

  const handleNext = () => {
    // Логика валидации может быть добавлена здесь
    onNext({ legalName, registrationNumber, dateOfIncorporate })
  }

  return (
    <div className={styles.container}>
      <Input
        id="legalName"
        label="Business Legal Name"
        type="text"
        placeholder="Enter your business legal name"
        value={legalName}
        onChange={(e) => setLegalName(e.target.value)}
      />
      <Input
        id="registrationNumber"
        label="Business Registration Number"
        type="text"
        placeholder="Enter your business registration number"
        value={registrationNumber}
        onChange={(e) => setRegistrationNumber(e.target.value)}
      />
      <Input
        id="dateOfIncorporate"
        label="Date of Incorporation"
        type="date"
        placeholder="Enter date of incorporation"
        value={dateOfIncorporate}
        onChange={(e) => setDateOfIncorporate(e.target.value)}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default BusinessInfo
