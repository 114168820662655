import { useState, useEffect } from "react"
import { useMutation, useLazyQuery } from "@apollo/client"
import { useNavigate, useLocation } from "react-router-dom"
import CountrySelector from "../components/CountrySelector"
import EmailVerification from "../components/EmailVerification"
import OTPEmailVerification from "../components/OTPEmailVerification"
import PhoneVerification from "../components/PhoneVerification"
import OTPPhoneVerification from "../components/OTPPhoneVerification"
import PersonalInfo from "../components/PersonalInfo"
import BusinessInfo from "../components/BusinessInfo"
import BusinessAddress from "../components/BusinessAddress"
import PasswordInput from "../components/PasswordInput"
import RegistrationTypeSelector from "../components/RegistrationTypeSelector"
import AccountExists from "../components/AccountExists"
import { REGISTER_USER } from "../apollo/graphql/Mutation/registerUser"
import { CREATE_BUSINESS } from "../apollo/graphql/Mutation/createBusiness"
import { USER_BY_EMAIL } from "../apollo/graphql/Query/userSummary"
import styles from "../assets/styles/Pages/RegisterPage.module.css"

function RegisterPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const isAddingBusiness = location.state?.isAddingBusiness || false

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [currentStep, setCurrentStep] = useState(1) // Начинаем с шага 1 для выбора страны
  const [accountType, setAccountType] = useState(
    isAddingBusiness ? "business" : null
  )
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  })
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
  })
  const [businessDetails, setBusinessDetails] = useState({
    legalName: "",
    registrationNumber: "",
    dateOfIncorporate: "",
  })
  const [businessAddress, setBusinessAddress] = useState({
    address: "",
    postalCode: "",
    unitNumber: "",
  })

  const [shouldRegister, setShouldRegister] = useState(false)
  const [userExists, setUserExists] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)

  const [registerUser] = useMutation(REGISTER_USER)
  const [createBusiness] = useMutation(CREATE_BUSINESS)
  const [getUserByEmail] = useLazyQuery(USER_BY_EMAIL)

  const handleNext = async (success = true) => {
    if (success) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleCountrySelection = (country) => {
    setSelectedCountry(country)
    if (isAddingBusiness) {
      setCurrentStep(7) // Перепрыгиваем на шаг 7 после выбора страны
    } else {
      handleNext(true)
    }
  }

  const handleEmailVerification = async (verifiedEmail, error) => {
    if (
      error &&
      typeof error === "string" &&
      error.includes("Email is already registered")
    ) {
      setUserExists(true)
      setEmail(verifiedEmail)
    } else {
      setEmail(verifiedEmail)
    }
    handleNext(true)
  }

  const handleOTPVerification = async () => {
    if (userExists) {
      setOtpVerified(true)
      handleNext(true)
    } else {
      handleNext(true)
    }
  }

  const handlePhoneVerification = (verifiedPhoneNumber) => {
    setPhoneNumber(verifiedPhoneNumber)
    handleNext(true)
  }

  const handlePersonalDetails = (details) => {
    setPersonalDetails(details)
    handleNext(true)
  }

  const handleBusinessDetails = (details) => {
    setBusinessDetails(details)
    handleNext(true)
  }

  const handleBusinessAddress = (address) => {
    setBusinessAddress(address)
    if (isAddingBusiness) {
      setShouldRegister(true) // Завершаем процесс после шага 8
    } else {
      handleNext(true)
    }
  }

  const handlePasswordInput = async (passwordData) => {
    setPasswords(passwordData)
    setShouldRegister(true)
    return true
  }

  useEffect(() => {
    if (shouldRegister) {
      handleRegistrationCompletion()
      setShouldRegister(false)
    }
  }, [shouldRegister])

  useEffect(() => {
    if (otpVerified && userExists) {
      getUserByEmail({ variables: { email } })
    }
  }, [otpVerified, userExists, getUserByEmail, email])

  const handleRegistrationCompletion = async () => {
    try {
      if (!isAddingBusiness) {
        // Регистрация пользователя (если не добавляем бизнес отдельно)
        const { data: userData } = await registerUser({
          variables: {
            email,
            phoneNumber,
            firstName: personalDetails.firstName,
            lastName: personalDetails.lastName,
            dateOfBirth: personalDetails.dateOfBirth,
            countryId: selectedCountry.id,
            password: passwords.password,
            confirmPassword: passwords.confirmPassword,
          },
        })

        if (userData.registerUser.success) {
          localStorage.setItem("token", userData.registerUser.token)
          localStorage.setItem(
            "refreshToken",
            userData.registerUser.refreshToken
          )
          localStorage.setItem(
            "wallet",
            JSON.stringify(userData.registerUser.wallet)
          )
        } else {
          console.error(
            "User registration failed:",
            userData.registerUser.errors
          )
        }
      }

      // Добавление бизнеса (если добавляем бизнес)
      if (accountType === "business" || isAddingBusiness) {
        const { data: businessData } = await createBusiness({
          variables: {
            legalName: businessDetails.legalName,
            registrationNumber: businessDetails.registrationNumber,
            dateOfIncorporate: businessDetails.dateOfIncorporate,
            address: businessAddress.address,
            postalCode: businessAddress.postalCode,
            unitNumber: businessAddress.unitNumber,
            countryId: selectedCountry.id,
          },
        })

        if (businessData.createBusiness.success) {
          localStorage.setItem(
            "wallet",
            JSON.stringify(businessData.createBusiness.wallet)
          )
          navigate("/wallet", { replace: true })
        } else {
          console.error(
            "Business creation failed:",
            businessData.createBusiness.errors
          )
        }
      }
    } catch (error) {
      console.error("Registration error:", error)
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.pageName}>Register</h1>

      {!accountType && !isAddingBusiness && (
        <RegistrationTypeSelector onSelect={setAccountType} />
      )}

      {otpVerified && userExists ? (
        <AccountExists email={email} />
      ) : (
        <>
          {accountType && currentStep === 1 && (
            <CountrySelector
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              onNext={handleCountrySelection}
            />
          )}

          {!isAddingBusiness && accountType && currentStep === 2 && (
            <EmailVerification onNext={handleEmailVerification} />
          )}

          {!isAddingBusiness && accountType && currentStep === 3 && (
            <OTPEmailVerification
              email={email}
              onSuccess={handleOTPVerification}
            />
          )}

          {!isAddingBusiness &&
            accountType &&
            currentStep === 4 &&
            selectedCountry && (
              <PhoneVerification
                country={selectedCountry}
                onNext={handlePhoneVerification}
              />
            )}

          {!isAddingBusiness && accountType && currentStep === 5 && (
            <OTPPhoneVerification
              phoneNumber={phoneNumber}
              onSuccess={handleNext}
            />
          )}

          {!isAddingBusiness && accountType && currentStep === 6 && (
            <PersonalInfo
              details={personalDetails}
              setDetails={setPersonalDetails}
              onNext={handlePersonalDetails}
            />
          )}

          {(accountType === "business" || isAddingBusiness) &&
            currentStep === 7 && (
              <BusinessInfo
                details={businessDetails}
                setDetails={setBusinessDetails}
                onNext={handleBusinessDetails}
              />
            )}

          {(accountType === "business" || isAddingBusiness) &&
            currentStep === 8 && (
              <BusinessAddress
                address={businessAddress}
                setAddress={setBusinessAddress}
                country={selectedCountry}
                onNext={handleBusinessAddress}
              />
            )}

          {!isAddingBusiness &&
          ((accountType === "personal" && currentStep === 7) ||
            (accountType === "business" && currentStep === 9)) ? (
            <PasswordInput onNext={handlePasswordInput} />
          ) : null}
        </>
      )}
    </div>
  )
}

export default RegisterPage
