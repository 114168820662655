import styles from "../styles/UI/Input.module.css"

const Input = ({
  id,
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  disabled,
  secondaryLabel,
  onSecondaryLabelClick,
  prefix,
  icon,
  rightButtonLabel,
  onRightButtonClick,
  rightText,
  error,
}) => {
  const showLeftContent = icon || prefix
  const isSecondaryLabelClickable = !!onSecondaryLabelClick

  return (
    <div
      className={`${styles.inputContainer} ${
        error ? styles.errorContainer : ""
      }`}
    >
      {label && (
        <label htmlFor={id} className={styles.inputLabel}>
          {label}
        </label>
      )}
      <div
        className={`${styles.inputWrapper} ${error ? styles.inputError : ""}`}
      >
        {showLeftContent && (
          <div className={styles.leftContent}>
            {icon && <img src={icon} alt="icon" className={styles.icon} />}
            {prefix && <span className={styles.prefix}>{prefix}</span>}
          </div>
        )}
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          className={`${styles.input} ${
            !showLeftContent ? styles.inputNoLeftContent : ""
          }`}
        />
        <div className={styles.rightContent}>
          {rightText && <span className={styles.rightText}>{rightText}</span>}
          {rightButtonLabel && (
            <button
              type="button"
              className={styles.rightButton}
              onClick={!disabled ? onRightButtonClick : null}
              disabled={disabled}
            >
              {rightButtonLabel}
            </button>
          )}
        </div>
      </div>
      <div className={styles.errorAndSecondaryLabel}>
        {error && <span className={styles.errorMessage}>{error}</span>}
        {secondaryLabel && (
          <label
            onClick={isSecondaryLabelClickable ? onSecondaryLabelClick : null}
            className={`${styles.secondaryLabel} ${
              isSecondaryLabelClickable ? "" : styles.secondaryLabelDisabled
            }`}
          >
            {secondaryLabel}
          </label>
        )}
      </div>
    </div>
  )
}

export default Input
