import styles from "../styles/UI/StatusBadge.module.css"

const StatusBadge = ({ status }) => {
  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return styles.completed
      case "pending":
        return styles.pending
      case "failed":
        return styles.failed
      default:
        return styles.default
    }
  }

  return (
    <div className={`${styles.statusBadge} ${getStatusClass(status)}`}>
      {status.toUpperCase()}
    </div>
  )
}

export default StatusBadge
