import styles from "../styles/UI/SearchBar.module.css"
import { CiSearch } from "react-icons/ci"

const SearchBar = ({ placeholder, value, onChange }) => {
  return (
    <div className={styles.searchBar}>
      <CiSearch className={styles.searchIcon} />
      <input
        type="text"
        className={styles.searchInput}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default SearchBar
