import { gql } from "@apollo/client"

export const CONVERT_SINGLE_CURRENCY = gql`
  query ConvertSingleCurrency(
    $walletId: UUID!
    $currency: String!
    $targetCurrency: String
  ) {
    convertSingleCurrency(
      walletId: $walletId
      currency: $currency
      targetCurrency: $targetCurrency
    ) {
      originalAmount
      convertedAmount
      originalCurrency {
        id
        name
        isoCode
        symbol
        logoUrl
        currencyType
      }
      targetCurrency {
        symbol
        currencyType
      }
      exchangeRate
      percentChange24h
    }
  }
`
