import { useState } from "react"
import { useMutation } from "@apollo/client"
import { VERIFY_USER } from "../apollo/graphql/Mutation/verifyUser"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/LoginInputs.module.css"

const LoginInputs = ({
  email,
  setEmail,
  setPassword,
  onSuccess,
  setTwoFactorAuthenticated,
}) => {
  const [password, setPasswordState] = useState("")
  const [error, setError] = useState(null)
  const [verifyUser, { loading }] = useMutation(VERIFY_USER)

  const handleVerifyUser = async () => {
    try {
      const { data } = await verifyUser({
        variables: { email, password },
      })

      if (data.verifyUser.success) {
        setError(null)
        setPassword(password) // Сохраняем пароль для дальнейшего использования
        onSuccess(true) // Успешная проверка
      } else {
        setError(
          data.verifyUser.errors
            ? data.verifyUser.errors.join(", ")
            : "Verification failed"
        )
        setTwoFactorAuthenticated(false) // Устанавливаем twoFactorAuthenticated в false при ошибке
        onSuccess(false) // Проверка неуспешна, переход не осуществляется
      }
    } catch (e) {
      setError("An error occurred. Please try again.")
      setTwoFactorAuthenticated(false) // Устанавливаем twoFactorAuthenticated в false при ошибке
      onSuccess(false) // Проверка неуспешна, переход не осуществляется
      console.error("Verification error:", e.message)
    }
  }

  return (
    <div className={styles.loginContainer}>
      <Input
        id="email"
        label="Email"
        type="email"
        placeholder="Enter your email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={error}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        placeholder="Enter your password"
        name="password"
        value={password}
        onChange={(e) => {
          setPasswordState(e.target.value)
          setPassword(e.target.value)
        }}
        error={error}
      />
      <Button
        onClick={handleVerifyUser}
        disabled={loading}
        className={styles.loginButton}
      >
        {loading ? "Verifying..." : "Verify and Send OTP"}
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  )
}

export default LoginInputs
