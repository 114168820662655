import { useState } from "react"
import { useMutation } from "@apollo/client"
import OTPInput from "../assets/UI/OTPInput"
import { VERIFY_PHONE_OTP } from "../apollo/graphql/Mutation/verifyPhoneOtp"
import { RESEND_PHONE_OTP } from "../apollo/graphql/Mutation/resendPhoneOtp"
import styles from "../assets/styles/Components/OTPPhoneVerification.module.css"

const OTPPhoneVerification = ({ phoneNumber, onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [verifyPhoneOtp] = useMutation(VERIFY_PHONE_OTP)
  const [resendPhoneOtp] = useMutation(RESEND_PHONE_OTP)

  const handleOTPComplete = async (otp) => {
    try {
      const { data } = await verifyPhoneOtp({
        variables: { phoneNumber, otp }, // Передаем номер телефона и OTP
      })

      const result = data?.verifyPhoneOtp

      if (result?.success) {
        setErrorMessage(null)
        onSuccess() // Вызов функции успеха при успешной верификации OTP
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
      } else {
        setErrorMessage("An unknown error occurred.")
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred. Please try again.")
      console.error("Verification error:", e.message)
    }
  }

  const handleResendOTP = async () => {
    try {
      const { data } = await resendPhoneOtp({
        variables: { phoneNumber }, // Передаем номер телефона
      })

      const result = data?.resendPhoneOtp

      if (result?.success) {
        setErrorMessage(null)
      } else if (result?.errors && result.errors.length > 0) {
        setErrorMessage(result.errors[0])
      } else {
        setErrorMessage("An unknown error occurred.")
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred. Please try again.")
      console.error("Resend error:", e.message)
    }
  }

  return (
    <div className={styles.container}>
      <OTPInput
        length={6}
        onComplete={handleOTPComplete}
        errorMessage={errorMessage}
        title="Enter the OTP sent to your phone"
        secondLabel="Resend Code"
        onSecondLabelClick={handleResendOTP}
      />
    </div>
  )
}

export default OTPPhoneVerification
