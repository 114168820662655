import { useQuery } from "@apollo/client"
import { GET_USER_WALLETS } from "../apollo/graphql/Query/userWallets"
import styles from "../assets/styles/Components/WalletList.module.css"

const WalletList = ({ onSelectWallet }) => {
  const { loading, error, data } = useQuery(GET_USER_WALLETS)

  if (loading) return <p>Loading wallets...</p>
  if (error) return <p>Error loading wallets: {error.message}</p>

  return (
    <div className={styles.walletList}>
      <h2>Select a Wallet</h2>
      <ul>
        {data.userWallets.map((wallet) => (
          <li key={wallet.id} onClick={() => onSelectWallet(wallet)}>
            {wallet.walletType} Wallet
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WalletList
