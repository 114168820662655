import { useState } from "react"
import styles from "../styles/UI/TabMenu.module.css"

const TabMenu = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key)

  return (
    <>
      <div className={styles.tabMenu}>
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={activeTab === tab.key ? styles.activeTab : ""}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {tabs.map((tab) => {
        if (tab.key === activeTab) {
          return <div key={tab.key}>{tab.content}</div>
        }
        return null
      })}
    </>
  )
}

export default TabMenu
