function renderFormattedCurrency(currencyType, symbol, formattedValue) {
  if (currencyType === "FIAT") {
    return (
      <>
        {symbol}
        {formattedValue}
      </>
    )
  } else {
    return (
      <>
        {formattedValue}
        {symbol && ` ${symbol}`}
      </>
    )
  }
}

export default renderFormattedCurrency
