import { gql } from "@apollo/client"

export const ALL_TRANSACTIONS = gql`
  query AllTransactions(
    $walletId: UUID!
    $fromDate: DateTime
    $toDate: DateTime
    $transactionTypes: [TransactionType]
    $currencyType: String
    $currencyIsoCode: String
  ) {
    allTransactions(
      walletId: $walletId
      fromDate: $fromDate
      toDate: $toDate
      transactionTypes: $transactionTypes
      currencyType: $currencyType
      currencyIsoCode: $currencyIsoCode
    ) {
      ... on CryptocurrencySendTransactionType {
        id
        sender
        receiver
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        txh
        fee
        sendStatus: status
        createdAt
      }
      ... on CryptocurrencyReceiveTransactionType {
        id
        sender
        receiver
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        txh
        receiveStatus: status
        createdAt
      }
      ... on PaymentTransactionType {
        id
        invoice
        sender
        receiver
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        txh
        fee
        paymentStatus: status
        createdAt
      }
      ... on SwapTransactionType {
        id
        fromCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        toCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        fromAmount
        toAmount
        exchangeRate
        fee
        swapStatus: status
        createdAt
      }
      ... on BusinessTransactionType {
        id
        senderWallet {
          id
          user {
            id
            email
            firstName
            lastName
          }
          business {
            id
          }
          walletType
        }
        receiverWallet {
          id
          user {
            id
            email
            firstName
            lastName
          }
          business {
            id
          }
          walletType
        }
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        fee
        businessStatus: status
        createdAt
      }
    }
  }
`
