import { gql } from "@apollo/client"

export const TOTAL_CONVERTED_BALANCE = gql`
  query TotalConvertedBalance($walletId: UUID!, $targetCurrency: String) {
    totalConvertedBalance(
      walletId: $walletId
      targetCurrency: $targetCurrency
    ) {
      totalConvertedBalance
      targetCurrency {
        name
        symbol
        isoCode
        currencyType
      }
    }
  }
`
